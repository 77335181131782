<template>
  <div class="particulars">
    <CompanyHeader titleurl="news" v-if="!isfromoa"/>
    <div class="particulars-center">
      <div class="product">
        <div class="product-content">
          <div class="news-head">
            <div class="news-head-title">{{newsinfo.title}}</div>
            <div class="news-head-date">{{newsinfo.createDate!= null &&newsinfo.createDate.length>0?'发布时间：'+newsinfo.createDate.substring(0,10):""}}</div>
          </div>
          <div v-html="newsinfo.newContent" class="newshtml"></div>
        </div>
      </div>
    </div>
    <Footer v-if="!isfromoa" />
  </div>
</template>
<script>
import { companygetcompanyinfo ,getnewdetail} from '@/api/company.js';
import CompanyHeader from '@/components/companyheader.vue';
import Footer from '@/components/footer.vue';
export default {
  name: 'App',
  components: {
    CompanyHeader,
    Footer,
  },
  data() {
    return {
      isfromoa: false,
      newsinfo: {},
      cid: '',
      newsid:'',
      cmpinfo: {},
      type: '',
      pageIndex: 1,
      pageSize: 1,
      totalCount:0,
    };
  },
  methods: {
    oncompanygetcompanyinfo() {
      companygetcompanyinfo({ companyid: this.cid }).then((res) => {
        this.cmpinfo = res.data.result;
      });
    },
    ongetnewdetail(){
        getnewdetail({ id: this.newsid }).then((res) => {
            this.newsinfo = res.data.result;
        });
    }
  },
  mounted() {
    var id = this.$route.query.id;
    this.newsid = this.$route.query.newsid;
    this.isfromoa = this.$route.query.fromoa == '1';
    this.cid = id;
    this.oncompanygetcompanyinfo();
    this.ongetnewdetail()
  },
};
</script>
<style scoped>
.newshtml{
    padding: 20px;
}
.particulars {
  width: 100%;
  background: #fff;
}

.particulars-center {
  width: 100%;
  min-width: 1280px;
}
.product {
  max-width: 1200px;
  margin: 0 auto;
}
.product .product-content {
  width: 1200px;
  min-width: 1200px;
  background: #fff;
  margin: auto;
}
.news-head{
  width: 1200px;
  margin: 30px auto 10px auto;
  text-align: center;
}
.news-head-title{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 18px;
  font-family: 600;
}
.news-head-date{
  margin-top: 10px;
  font-size: 15px;
  color:#8F9AAC;
}
</style>
